import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator'

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  })
  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  )
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value })
    if (validator.allValid()) {
      validator.hideMessages()
    } else {
      validator.showMessages()
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (validator.allValid()) {
      validator.hideMessages()
      setForms({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
      })
    } else {
      validator.showMessages()
    }
  }

  return (
    <form
      method="POST"
      action="https://formsubmit.co/ken@vermillionsky.com"
      // onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Name"
            />
            {validator.message('name', forms.name, 'required|alpha_space')}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Email"
            />
            {validator.message('email', forms.email, 'required|email')}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Message"
          ></textarea>
          {validator.message('message', forms.message, 'required')}
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          Submit Now
        </button>
      </div>
    </form>
  )
}

export default ContactForm
