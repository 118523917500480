import React from 'react'
import abImg from '../../images/about-image.jpg'
import icon1 from '../../images/about-icon-1.png'
import icon2 from '../../images/about-icon-2.png'

const About = (props) => {
    return (
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Us</span>
                                <h2>Shaping Tomorrow Together!</h2>
                            </div>
                            <p className="sub-text">At Common Sense Politics (CSPNow), we're not just another platform; we're a movement. Rooted in the urgent need to bridge the growing chasm in American politics, CSPNow serves as a dedicated hub where individuals from across the political spectrum can convene, exchange ideas, and discover common ground. Our fundamental goal is to transcend partisan divides, nurturing a discourse that values moderation and pragmatic approaches. Through every dialogue, disagreement, and consensus, we're cultivating a future where political discourse thrives on collaboration, not conflict. Join us in shaping a political landscape that prioritizes moderation, embraces diversity of thought, and advances solutions for the common good.</p>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="about-content">
                                        <div className="images">
                                            <img src={icon1} alt=""/>
                                        </div>
                                        <div className="text">
                                            <h3>OUR MISSION</h3>
                                            <p>To unite the political center by fostering respectful dialogue and collaborative solutions, building a more inclusive and productive political landscape for all.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="about-content">
                                        <div className="images">
                                            <img src={icon2} alt=""/>
                                        </div>
                                        <div className="text">
                                            <h3>DONATE</h3>
                                            <p>Support unity. Donate to shape a brighter future.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
