import React from 'react'
import hero from '../../images/slider/home-slider.jpg'

const Hero4 = () => {
  return (
    <section className="wpo-static-hero">
      <div className="static-hero hero-s2">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container">
              <div className="hero-content">
                <div
                  className="slide-title"
                  style={{ backgroundColor: 'rgb(53 80 190 / 80%)' }}
                >
                  <h2>Bridging Political Divides</h2>
                </div>
                <div
                  className="slide-text"
                  style={{
                    paddingRight: 10,
                    backgroundColor: 'rgb(53 80 190 / 80%)',
                  }}
                >
                  <p>
                    Embark on a journey of discovery at CSPNow: where moderates
                    converge to engage in constructive and nuanced
                    conversations, fostering understanding and empathy across
                    the political spectrum. Explore a community where
                    differences are celebrated, not divisive; where dialogue
                    transcends partisan lines to uncover shared values and
                    solutions. Join us in building bridges and cultivating unity
                    amidst the vibrant mosaic of political thought.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div className="btns">
                  <a href="https://mctest.cspnow.org" className="theme-btn-s3">
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="consult-pic">
          <img src={hero} alt="" />
        </div>
        <div className="hero-line-shape-1">
          <svg width="520" height="478" viewBox="0 0 520 478" fill="none">
            <circle opacity="0.05" cx="194.5" cy="152.5" r="325.5" />
          </svg>
        </div>
        <div className="hero-line-shape-2">
          <svg width="997" height="581" viewBox="0 0 997 581" fill="none">
            <circle opacity="0.05" cx="508" cy="508" r="508" />
          </svg>
        </div>
      </div>
      <div className="hero-line-shape-3">
        <svg width="779" height="779" viewBox="0 0 779 779" fill="none">
          <path d="M0 0H779V779L0 0Z" />
        </svg>
      </div>
    </section>
  )
}

export default Hero4
